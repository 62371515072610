import React from "react"
import "./linkCard.scss"
import { Link } from "gatsby"
import Button from "../button/button";
import RightArrow from "../../images/icons/right-arrow-icon.svg"

function LinkCard({img, alt, cardClass, cardTitle, cardText, cardLink, linkID, linkImgID, linkTitleID, linkTxtID, btnLink, btnTitle,btnClass}) {
    return (        
        <figure className={"link-card " + cardClass}>
            {cardLink ? 
            <div>
                <Link id={linkID} to={cardLink} title={cardTitle}>
                    <div id={linkID} className="this-is-needed-for-tagging">
                        <figcaption>
                            <h4 id={linkTitleID}>{cardTitle}</h4>
                            <p id={linkTxtID}>{cardText}</p>
                        </figcaption>
                    </div>
                </Link>
                <img id={linkImgID} src={img} alt={alt} width={"50px"} height={"50px"} />
            </div>
            :
            <div id={linkID} className="this-is-needed-for-tagging">
                <figcaption>
                    <h4 id={linkTitleID}>{cardTitle}</h4>
                    <p className={`${btnLink? "m-b-10" : ""} black`} id={linkTxtID}>{cardText}</p>
                    {btnLink && <Link id={linkID} to={btnLink}><i className={"icon-right-arrow " + btnClass}><img src={RightArrow} className="icon" /></i></Link>}
                </figcaption>
                <img id={linkImgID} src={img} alt={alt} width={"50px"} height={"50px"} />
            </div>
            }

            {btnTitle && <Button text={btnTitle} url={btnLink} className={cardClass} />}
        </figure>        
    );
}

export default LinkCard