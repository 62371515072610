import React from "react"
import { Fragment } from 'react';

import Layout from "../../../templates/layout"
import SEO from "../../../components/seo/seo"
import Hero from "../../../components/hero/hero"
import Card from "../../../components/card/card"
import HaveQuestions from "../../../components/haveQuestions/haveQuestions"
import BrandLogo from "../../../images/logos/elitek-logo.svg"
import QuestionsIcon from "../../../images/icons/icon-questions-elitek.svg"
import HeroIcon from "../../../images/icons/elitek_reimbursement_icon.svg"
import BackToTop from "../../../components/backToTop/BackToTop"
import PrescribingInfo from "../../../components/prescribingInfo/prescribingInfo"
import AssistCallout from "../../../components/assistCallout/AssistCallout"
import LinkCard from "../../../components/linkCard/linkCard";

import RX from "../../../images/icons/elitek/elitek-rx-icon.svg"
import List from "../../../images/icons/elitek/elitek-list-icon.svg"
import Docs from "../../../images/icons/elitek/elitek-docs-icon.svg"
import Book from "../../../images/icons/elitek/elitek-book-icon.svg"

const pageContent = () => (
  <Layout pageid="page-reimbursement" pageClass="mobile-height-hero">
    <SEO
      title="Access and reimbursement for eligible patients prescribed ELITEK® (rasburicase) | HCP Site"
      keywords="CareASSIST, Sanofi, ELITEK® (rasburicase), HCP Site, Access and Reimbursement"
      description="Learn about access support for ELITEK available through CareASSIST, including benefits investigations, prior authorization investigations, coding/billing support, and claims/appeals. See full Prescribing Info, including Boxed WARNING"
    />

    <Hero
      brandLogo={BrandLogo}
      headline="Access &amp; reimbursement"
      copy=""
      heroImgClass="hero-icon"
      hasButton={false}
      hasLogo={true}
      hasIcon={true}
      heroIcon={HeroIcon}
      iconAlt="Access & Reimbursement Icon"
      brandAlt="Elitek logo"
    />

    <section className="content-section mobile-p-t-15">
      <main>
        <div className="main-content">

          <AssistCallout imgAlt="Copay card icon" productClass="elitek" img={RX} content="Our Care Managers assess coverage and identify support options for your patients, beginning with insurance verification. They can also assist with the following:" />      
          

          <div className="flex-section evenly home-card-container">
          {/* Vertical Icon Card Examples - no extra class needed */}
          <LinkCard
          img={List}
          alt="Prior Authorization, Denials and Appeals"          
          cardTitle={<>Prior authorization,<br/>denials, and appeals</>}
          cardText="Sample letters for PA and appeals processes"
          cardLink=""
          cardClass="hcp-link-card"
          linkID="financial-assistance-link"
          linkImgID="financial-assistance-link-image"
          linkTitleID="financial-assistance-link-title"
          linkTxtID="financial-assistance-link-text"
          />
          <LinkCard
          img={Docs}
          alt="Claims icon"
          cardTitle={<Fragment>Claims <br/>information</Fragment>}
          cardText="Insight on claims submittal"
          cardLink=""
          cardClass="hcp-link-card"
          linkID="enrollment-link"
          linkImgID="enrollment-link-image"
          linkTitleID="enrollment-link-title"
          linkTxtID="enrollment-link-text"
          />
          <LinkCard
          img={Book}
          alt="Coding-billing icon"
          cardTitle={<Fragment>Coding <br/>& billing</Fragment>}
          cardText="Sample coding and billing documents to assist your office"
          cardLink=""
          cardClass="hcp-link-card"
          linkID="faq-link"
          linkImgID="faq-link-image"
          linkTitleID="faq-link-title"
          linkTxtID="faq-link-text"
          />
        </div>

        <AssistCallout productClass="elitek" content="To learn more about access and reimbursement or additional support options, please contact your local Field Reimbursement Manager." />      

        </div>
        
      </main>

      <PrescribingInfo linkId="hcp-elitek-reimbursement-prescribing-information" link="https://products.sanofi.us/elitek/elitek.html" />
      <HaveQuestions icon={QuestionsIcon} />
    </section>
    <div className="back-to-top-container">
      <BackToTop />
    </div>
  </Layout>
);

export default pageContent