import React from "react";

import './assistCallout.scss'

const AssistCallout = ({img, productClass, content, hasGoBtn, link, additionalClass, imgAlt, linkId}) => (
    <section className={`assistcallout-conainer ${productClass}`}>
        {img && <img alt={imgAlt} src={img} />}
        <p className={additionalClass}>{content}</p>
        {hasGoBtn ? <a id={linkId} href={link} target="_blank"><span className={`go-btn ${productClass}`}></span></a> : ""}
    </section>
)

export default AssistCallout